import {add, format, getDay, getISOWeek, parse, parseISO, startOfWeek} from 'date-fns';

export const FormatIsoDate = (date) => {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'dd.MM.yyyy');
}

export const FormatIsoDateWithOutTimeZone = (date) => {
    const parsedDate = new Date(date)
    return parsedDate.toISODate()
}


export const FormatDate = (date) => {
    return format(date, 'dd.MM.yyyy');
}

export const ParseDate = (date) => {
    const parsedDate = parse(date, 'dd.MM.yyyy', new Date());
    return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'");
}

export const ReadDate = (date) => {
    let selected = new Date();
    if (date !== undefined)
        selected = parse(date, 'dd.MM.yyyy', new Date());
    return startOfWeek(selected, { weekStartsOn: 1 })
}

export const DateFromWeekNum = (week, date = new Date()) => {
    const year = date.getFullYear();
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}

export const WeekNumber = (date = new Date()) => {
    return getISOWeek(date);
}

export const isWeekend = (date) => {
    const dayNumber = getDay(date);
    return dayNumber === 6 || dayNumber === 0;
}

export const isOneDayOld = (date) => {
    const parsedDate = parseISO(date);
    return new Date() < add(parsedDate, {days: 1});
}


if (!Date.prototype.toISODate) {
    Date.prototype.toISODate = function() {
        return  ('0'+ this.getDate()).slice(-2) + '.' +
            ('0'+ (this.getMonth()+1)).slice(-2) + "." +
            this.getFullYear();
    }
}

