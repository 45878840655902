import React, {useEffect, useState} from 'react';
import classes from './Select.module.scss'
import ReactSelect from 'react-select';
import {useTranslation} from "react-i18next";
import {haveDefaultValue, haveError} from "../../../utils/form";
import cn from 'classnames';

const Select = (props) => {
    const {t} = useTranslation();
    const htmlFor = `select-${Math.random()}`
    const [width, setWidth] = useState(window.innerWidth);
    const [small, setSmall] = useState(props.small);

    const error = haveError(props);
    const defaultValue = haveDefaultValue(props);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    useEffect(() => {
        if (props.small === false)
            return;

        if (width < 700) setSmall(false);
        if (width >= 700) setSmall(true);
    }, [width, props.small]);

    const getTranslatedOptions = (items) => {
        return items.map((item) => {
            return {label: t(item.label), value: item.value};
        });
    }

    const onResize = (e) => {
        setWidth(window.innerWidth);
    }

    const selected = getTranslatedOptions(props.options)?.find(element => {
        return element.value === defaultValue
    });

    const onRegularOptionChange = (selectedOption) => {
        props.onChange({
            target: {
                name:  props.name,
                value: selectedOption.value
            }
        });
    }
    const onSmallSelectChange = (event) => {
        props.onChange({
            target: {
                name:  props.name,
                value: event.target.value
            }
        });
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            padding: classes.padding,
            borderColor: error ? classes.errorColor : classes.borderColor,
            boxShadow: 'none',
            ':hover': { borderColor: classes.primaryHoverColor}
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: classes.fontSize,
            lineHeight: classes.lineHeight,
            color: classes.blackColor
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: classes.fontSize,
            lineHeight: classes.lineHeight,
            color: state.isSelected ? classes.primaryColor :classes.blackColor,
            backgroundColor: classes.whiteColor
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontSize: classes.fontSize,
                color: classes.blackColor
            }
        }
    }

    if (small) {
        return (
            <div className={cn(classes.select, classes.small)}>
                {(props.label || defaultValue !== null) && <label htmlFor={htmlFor}>
                    {t(props.label || props.placeholder)} :
                </label>  }
                <select onChange={onSmallSelectChange}
                        defaultValue={defaultValue}
                        disabled={props.disabled}
                >
                    {props.options.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                </select>
            </div>
        );
    }

    return (
        <div className={cn("form-field", classes.select)} style={props.style}>

            {(props.label || defaultValue !== null) && <label htmlFor={htmlFor}>
                {props.label || props.placeholder} </label> }

            <ReactSelect
                id={htmlFor}
                styles={customStyles}
                value={selected}
                onChange={onRegularOptionChange}
                options={getTranslatedOptions(props.options)}
                placeholder={<div className="select-placeholder-text">{t(props.placeholder)}</div>}
                isDisabled={props.disabled}
                components={{
                    IndicatorSeparator: () => null
                }} />

            { error &&  <span className="errorMessage">{error || t('Required')}</span> }
        </div>
    )
}

Select.defaultProps = {
    small: false,
    style: {}
}

export default Select
