import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

const NEW_ORDER = gql`
    query newOrder($type: ScheduleType!) {
        newOrder(type: $type) {
            officeCode
            quickDate
            complexDate
            disabledDates
        }
    }`;

export const useNewOrder = () => useLazyQuery(NEW_ORDER, {});

export const ORDER_FIELDS = gql`
    fragment OrderFields on OrderType {
        id
        type
        number
        phone
        weekNumber
        year
        title
        shouldCompletedAt
        description
        data
        comment
        employee
        completed
        sortNumber
        createdAt
    }
`;

const SAVE_ORDER = gql`
    ${ORDER_FIELDS}
    mutation SaveOrder($input: OrderInput!) {
        saveOrder(
            orderInput: $input
        ) {
            ...OrderFields
        }
    }`;

export const useSaveOrder = () => useMutation(SAVE_ORDER, {refetchQueries: [
    { query: GET_ORDERS }
]});

const GET_ORDERS = gql`
    ${ORDER_FIELDS}
    query Orders {
        orders {
            ...OrderFields
        }
    }`;
export const useOrders = (variables) => useQuery(GET_ORDERS, {variables});

const GET_ORDERS_IN_PERIOD = gql`
  ${ORDER_FIELDS}
  query OrdersInPeriod($from: DateTime!, $till: DateTime!) {
      ordersInPeriod(from: $from, till: $till) {
          ...OrderFields
      }
  }`;

export const useOrdersInPeriod = () => useLazyQuery(GET_ORDERS_IN_PERIOD);

const GET_ORDER = gql`
    ${ORDER_FIELDS}
    query order($id: Uuid!) {
        orders(where: {id: {eq: $id}}) {
            ...OrderFields
        }
    }`;

export const useOrder = () => useLazyQuery(GET_ORDER);

const GET_SORT = gql`
    query getSort {
        offices(order: {sortNumber: ASC}) {
            id
            code
            name
        }
    }`;

export const useSort = () => useQuery(GET_SORT);

const DELETE_ORDER = gql`
    mutation DeleteOrder($id: Uuid!) {
      deleteOrder (id: $id) 
    }`;

export const useDeleteOrder = () => useMutation(DELETE_ORDER, {refetchQueries: [
        { query: GET_ORDERS }
    ]});


const GET_COMMENTS= gql`
    query Comments {
        comments {
            weekNumber
            year
            text
        }
    }`;

export const useCommentsLazy = () => useLazyQuery(GET_COMMENTS);


const SAVE_COMMENT = gql`
    mutation SaveWeekComment($input: WeekCommentInput!) {
        saveWeekComment(
            input: $input
        )
    }`;

export const useSaveComment = () => useMutation(SAVE_COMMENT);
