
export function valOrZero(val) {
    return +val;
}

export const findChildrenInObject = (obj, id) => {
    if (obj.id === id)
        return obj;

    if (!obj.children)
        return;

    let result;

    for (let i = 0 ; i < obj.children.length; i++) {
        const item = obj.children[i];
        if (item.id === id) {
            result = item;
            break;
        } else {
            result = findChildrenInObject(item, id);
            if (result) break;
        }
    }

    return result;
}

export const findParentInObject = (obj, id) => {
    if (obj.id === id || !obj.children)
        return;

    let result;

    for (let i = 0 ; i < obj.children.length; i++) {
        const item = obj.children[i];
        if (item.id === id) {
            result = obj;
            break;
        } else {
            result = findParentInObject(item, id);
            if (result) break;
        }
    }

    return result;
}

export const newId = () => (
    Math.random().toString(36).substr(2, 5)
);

export const objectToLabelValueArray = (obj) => {
    const result = [];

    for (const [key, value] of Object.entries(obj)) {
        result.push({label: key, value: value});
    }

    return result;
}

export function isObjectEmpty(obj) {
    return (
        Object.keys(obj).length === 0 &&
        Object.getOwnPropertySymbols(obj).length === 0 &&
        obj.constructor === Object
    );
}

export const stripTrailingSlash = (str) => {
    if (str.length === 0)
        return str;

    if(str.substr(-1) === '/')
        return str.substr(0, str.length - 1);

    return str;
}

export const UuidTOGuid = (str) => {
    const regexValue = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
    return str.replace(regexValue, '$1-$2-$3-$4-$5');
}

export const isEmpty = (str) => {
    if (str === null || str === undefined) return null;
    return str.length > 0 ? str : null;
}

export const isNotEmpty = (str) => {
    if (str === null || str === undefined) return false;
    return str.length > 0;
}

export const newGuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
