import React, {useState} from 'react';
import classes from './Materials.module.scss';
import Button from '../../UI/Button/Button';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useMaterials} from '../../../appolo/admin.queries';
import {MaterialFields} from './Material/Settings';
import cn from 'classnames';
import {FormatIsoDate} from '../../../utils/date';

const Materials = (props) => {
    let history = useHistory();
    const {t} = useTranslation();
    const { loading, error, data} = useMaterials();

    const [canManage] = useState(props.role === 'admin' || props.role === 'manager');

    const gridLength = MaterialFields.length;

    const onClick = (data) => {
        if (!canManage) {
            return;
        }

        history.push(`/materials/${data.id}`);
    }

    const Status = ({status}) => {
        switch (status) {
            case "low":
                return(<span className={cn(classes.status, classes.low)} title={t("Low")}></span>);
            case "medium":
                return(<span className={cn(classes.status, classes.medium)} title={t("Medium")}></span>);
            case "high":
                return(<span className={cn(classes.status, classes.high)} title={t("High")}></span>);
            default:
                return(<></>);
        }
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <>
            <h1>{t("Materials")}</h1>
            {canManage ? <div className={classes.panel}>
                <Button type="secondary" onClick={() => history.push("/materials/new")} >{t("Add material")}</Button>
            </div> : undefined}

            <div className={cn(classes.title, classes.grid)} style={{gridTemplateColumns: `200px repeat(${gridLength}, 1fr)`}}>
                <div>{t("Name")}</div>
                {MaterialFields.map(x => (<div key={`column-${x.id}`} className={classes.column}>{x.name}</div>))}
            </div>


            {data.materials.map(x => {
                const data = JSON.parse(x.data);
                return ( <div key={`row-${x.id}`} className={cn(classes.grid, classes.row)} style={{gridTemplateColumns: `200px repeat(${gridLength}, 1fr)`}}>
                    <div onClick={() => onClick(x)}>
                        {x.name}
                        <div className={classes.subtext}>{FormatIsoDate(x.updatedAt)}</div>
                    </div>
                    {MaterialFields.map(m => (<div key={`row-${data.id}-${m.id}`} className={classes.column}><Status status={data.find(f => f.id === m.id)?.value} /></div>))}
                </div>);
            })}

        </>
    );
};

Materials.defaultProps = {};

export default Materials;
