export const OrderFields = [
    {
        id: "monument",
        checked: false,
        name: "Monument",
        value: null,
        employee: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "portrait",
        checked: false,
        name: "Portrait",
        value: null,
        employee: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "art",
        checked: false,
        name: "Art",
        value: null,
        employee: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "visualisation",
        checked: false,
        name: "Visualisation",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "rt01",
        checked: false,
        name: "RT01",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "rt03",
        checked: false,
        name: "RT03",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "rt04",
        checked: false,
        name: "RT04",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "rt05",
        checked: false,
        name: "RT05",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "rt06",
        checked: false,
        name: "RT06",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "rt07",
        checked: false,
        name: "RT07",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "rt15",
        checked: false,
        name: "RT15",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    },
    {
        id: "other",
        checked: false,
        name: "Other",
        value: null,
        completed: false,
        showOnGrid: true,
        status: "WAITING",
    }
];
