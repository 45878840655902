import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {ApolloClient, ApolloProvider, InMemoryCache, createHttpLink} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import App from './App';
import {setContext} from "@apollo/client/link/context";
import authService from './components/api-authorization/AuthorizeService';
import './i18n';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'include'
});

const authLink = setContext(async (_, { headers }) =>  {
    const token = await authService.getAccessToken();
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(async error =>
        {
            if (error.extensions.code === "AUTH_NOT_AUTHENTICATED") {
                await authService.completeSignOut();
            }
        });
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(errorLink.concat(httpLink))
});


ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
  </BrowserRouter>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

