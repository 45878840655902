import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useOffice, useSaveOffice} from "../../../../appolo/admin.queries";
import Input from "../../../UI/Input/Input";
import * as yup from "yup";
import {notHaveErrors, validateFields, validateForm} from "../../../../utils/form";
import {useTranslation} from "react-i18next";
import Button from "../../../UI/Button/Button";

export const Office = (props) => {
    const {t} = useTranslation();
    let history = useHistory();
    let { id } = useParams();

    if (props.role !== "admin") {
        history.push("/");
    }

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const { loading, error, data} = useOffice({id: id !== "new" ? id : "00000000-0000-0000-0000-000000000000"});
    const [saveOffice] = useSaveOffice();

    useEffect(() => {
        if (!data || data.offices?.length === 0)
            return;

        const newData = {
            name: data.offices[0].name,
            code: data.offices[0].code,
            address: data.offices[0].address,
            sortNumber: data.offices[0].sortNumber
        }

        setValues(newData);
    }, [data]);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require")),
        code: yup.string().required(t("Require")),
        address: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const onSave = () => {
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                sendToServer();
            else
                setErrors(errors);
        });
    }

    const sendToServer = () => {
        let variables = {
            id: '00000000-0000-0000-0000-000000000000',
            name: values.name,
            code: values.code,
            sortNumber: +values.sortNumber,
            address: values.address
        };

        if (id && id !== "new")
            variables.id = id;

        saveOffice({variables})
            .then(() => {
                history.push("/offices");
            });
    }

    if (id !== "new" && loading) return <div>Loading...</div>;
    if (id !== "new" && error) return <div>Error!</div>;

    return (
        <>
            <h1>Office</h1>
            <div>
                <Input
                    name="name"
                    placeholder={t('Name')}
                    {...regField}
                />
                <Input
                    placeholder={t('Code')}
                    name="code"
                    {...regField}
                />
                <Input
                    placeholder={t('Sort number')}
                    name="sortNumber"
                    {...regField}
                />
                <Input
                    placeholder={t('Address')}
                    name="address"
                    {...regField}
                />

                <div>
                    <Button onClick={onSave} type="primary">{t('Save')}</Button>
                    <Button onClick={() => history.push('/offices')} type="secondary">{t('Cancel')}</Button>
                </div>
            </div>
        </>
    );
}
