import React, {useState} from 'react';
import classes from './LanguageSwitcher.module.scss';

const LanguageSwitcher = (props) => {
    const [currentLanguageIndex, setCurrentLanguageIndex] = useState(props.languages.findIndex(x => x.value === props.currentLanguage));

    const onChange = () => {
        const next = currentLanguageIndex + 2 > props.languages.length ? 0 : currentLanguageIndex + 1;
        setCurrentLanguageIndex(next);
        props.onChange(props.languages[next].value);
    }

    return (
        <li className="nav-item"><button className={classes.language} onClick={onChange}>{props.languages[currentLanguageIndex].label}</button></li>
    );
};

LanguageSwitcher.defaultProps = {
    currentLanguage: 'lv',
    languages: [
        {label: 'En', value: 'en'},
        {label: 'Ru', value: 'ru'},
        {label: 'Lv', value: 'lv'}
    ],
    onChange: () => {}
};

export default LanguageSwitcher;