import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Orders } from './components/Pages/Orders/Orders';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import {Users} from "./components/Pages/Users/Users";
import {User} from "./components/Pages/Users/User/User";
import {Offices} from "./components/Pages/Offices/Offices";
import {Office} from "./components/Pages/Offices/Office/Office";
import {Order} from './components/Pages/Orders/Order/Order';
import './custom.css'
import Home from './components/Pages/Home';
import WeekReport from './components/Pages/Reports/Report/WeekReport';
import ArtReport from './components/Pages/Reports/Report/ArtReport';
import Materials from './components/Pages/Materials/Materials';
import Material from './components/Pages/Materials/Material/Material';
import Archive from './components/Pages/Archive/Archive';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout className="light-theme">
        <Route exact path="/" component={Home} />
        <AuthorizeRoute exact path='/orders' component={Orders} />
        <AuthorizeRoute path='/orders/:id' component={Order} />
        <AuthorizeRoute exact path='/archive' component={Archive} />
        <AuthorizeRoute exact path='/materials' component={Materials} />
        <AuthorizeRoute path='/materials/:id' component={Material} />
        <AuthorizeRoute path='/reports/week/:date?' component={WeekReport} />
        <AuthorizeRoute path='/reports/art/:date?' component={ArtReport} />
        <AuthorizeRoute exact path='/users' component={Users} />
        <AuthorizeRoute path='/users/:id' component={User} />
        <AuthorizeRoute exact path='/offices' component={Offices} />
        <AuthorizeRoute path='/offices/:id' component={Office} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
