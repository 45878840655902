import React from 'react';
import { useTable } from 'react-table';
import classes from './Table.module.scss'

const Table = ({columns, data, onAddClick, onRowClick})  => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    if (rows.length === 0) return (<div>Empty...</div>);

    return (
        <table className={classes.Table} {...getTableProps()} >
            <thead className={classes.header}>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th className={column.className} {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody className={classes.tbody} {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr onClick={() => onRowClick(row.original)}  {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
}

export default Table;