import React from 'react'
import classes from './Checkbox.module.scss'
import {haveError} from "../../../utils/form";

const Checkbox = props => {
    const cls = ['form-field', classes.Checkbox]
    const htmlFor = `checkbox-${Math.random()}`

    const error = haveError(props);

    if (error) {
        cls.push(classes.invalid)
    }

    return (
        <div className={cls.join(' ')}>
            <div>
                <label htmlFor={htmlFor}>
                    <input
                        name={props.name}
                        type="checkbox"
                        id={htmlFor}
                        value={props.value || props.name}
                        onChange={props.onChange}
                    />
                    &nbsp; {props.label}
                </label>
            </div>
        </div>
    )
}

export default Checkbox