import React, {useEffect, useState} from 'react';
import classes from './SwitchTabs.module.scss';
import cn from 'classnames';

const SwitchTabs = (props) => {
    const [selected, setSelected] = useState(props.selected);

    const onClick = (item) => {
      setSelected(item.value);
      props.onSwitch(item.value);
    }

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <div className={classes.SwitchTabs}>
            {props.tabs.map(x => (
                <button key={x.value} onClick={() => onClick(x)} className={cn({[classes.selected]: selected === x.value})}>{x.label}</button>
            ))}
        </div>
    );
};

SwitchTabs.defaultProps = {
    tabs: [
        {label: 'Label 1', value: 1},
        {label: 'Label 2', value: 2}
    ],
    onSwitch: (() => {
    }),
    selected: 1
};

export default SwitchTabs;