import React, {useEffect, useState} from 'react';
import {useUsers} from "../../../appolo/admin.queries";
import Table from "../../UI/Table/Table";
import { useHistory } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import authService from '../../api-authorization/AuthorizeService';
import classes from './Users.module.scss';
import Button from '../../UI/Button/Button';

export const Users = (props) => {
    const {t} = useTranslation();
    let history = useHistory();
    const [token, setToken] = useState();

    if (props.role !== "admin") {
        history.push("/");
    }

    useEffect( () => {
        const getToken = async () => {
            return await authService.getAccessToken();
        };
        getToken().then((data) => setToken(data));
    }, [])


    const { loading, error, data} = useUsers();

    const onClick = (data) => {
        console.log(data);
        history.push(`/users/${data.id}`);
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <>
        <h1>{t("Users")}</h1>
        <div className={classes.panel}>
            <Button type="secondary" onClick={() => history.push("/users/new")} >{t("Add user")}</Button>
        </div>
        <Table
            data={data.users}
            columns={[
                {
                    Header: t('Name'),
                    accessor: 'name'
                },
                {
                    Header: t('E-mail'),
                    accessor: 'email'
                },
                {
                    Header: t('Role'),
                    accessor: 'role',
                    Cell: (cellData) => <div>{t(cellData.value)}</div>
                },
            ]}
            onRowClick={onClick}
            />

            {token && <div className={classes.token}>{token}</div>}
        </>
    );
}