import {gql, useMutation, useQuery} from '@apollo/client';

const GET_USERS = gql`
    query Users {
        users {
            id
            name
            email
            role
            office
        }
    }`;

export const useUsers = () => useQuery(GET_USERS);

const GET_USER = gql`
    query Users($userId: String!) {
        users(where: {id:{eq:$userId}}) {
            id
            name
            email
            role
            office
        }

        roles {
            value: id
            label: name
        }

        offices {
            value: id
            label: name
        }
    }
`;

export const useUser = (variables) => useQuery(GET_USER, {variables});

const SAVE_USER = gql`
    mutation SaveUser(
        $id: String
        $name: String!
        $email: String!
        $password: String
        $role: String!
        $office: Uuid
    ) {
        saveUser(userInput: {
            id: $id
            name: $name
            email: $email
            password: $password
            role: $role
            office: $office
        }) {
            id
            name
            email
            role
            office
        }
    }`;

export const useSaveUser = () => useMutation(SAVE_USER);


const GET_OFFICES = gql`
    query GetOffices {
        offices {
            id
            name
            code
            sortNumber
            address
        }
    }`;

export const useOffices = () => useQuery(GET_OFFICES);

const GET_OFFICE = gql`
    query GetOffices(
        $id: Uuid!
    ) {
        offices(where: {id: {eq: $id}}) {
            id
            name
            code
            sortNumber
            address
        }
    }`;

export const useOffice = (variables) => useQuery(GET_OFFICE, {variables});

const SAVE_OFFICE = gql`
    mutation SaveOffice(
        $id: Uuid!,
        $name: String!
        $code: String!
        $sortNumber: Int!
        $address: String!
    ) {
        saveOffice(officeInput: {
            id: $id
            name: $name
            code: $code
            sortNumber: $sortNumber
            address: $address
        }) {
            id
            name
            code
            sortNumber
            address
        }
    }`;

export const useSaveOffice = () => useMutation(SAVE_OFFICE, {refetchQueries: [GET_OFFICES]});



const GET_MATERIALS = gql`
    query GetMaterials {
        materials {
            id
            name
            data
            updatedAt
        }
    }`;

export const useMaterials = () => useQuery(GET_MATERIALS);

const GET_MATERIAL = gql`
    query GetMaterial(
        $id: Uuid!
    ) {
        materials(where: {id: {eq: $id}}) {
            id
            name
            data
            updatedAt
        }
    }`;

export const useMaterial = (variables) => useQuery(GET_MATERIAL, {variables});

const SAVE_MATERIAL = gql`
    mutation SaveMaterial(
        $id: Uuid!,
        $name: String!
        $data: String!
    ) {
        saveMaterial(materialInput: {
            id: $id
            name: $name
            data: $data
        }) {
            id
            name
            data
            updatedAt
        }
    }`;

export const useSaveMaterial = () => useMutation(SAVE_MATERIAL, {refetchQueries: [GET_MATERIALS]});
