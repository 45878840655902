import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSaveUser, useUser} from "../../../../appolo/admin.queries";
import Input from "../../../UI/Input/Input";
import * as yup from "yup";
import {notHaveErrors, validateFields, validateForm} from "../../../../utils/form";
import {useTranslation} from "react-i18next";
import Select from "../../../UI/Select/Select";
import Button from "../../../UI/Button/Button";

export const User = (props) => {
    const {t} = useTranslation();
    let history = useHistory();
    let { id } = useParams();

    if (props.role !== "admin") {
        history.push("/");
    }

    const [values, setValues] = useState({password: "", passwordAgain: ""});
    const [errors, setErrors] = useState({});

    const { loading, error, data} = useUser({userId: id});
    const [saveUser] = useSaveUser();


    useEffect(() => {
        if (!data || data.users?.length === 0)
            return;

        const newData = {
            name: data.users[0].name,
            email: data.users[0].email,
            role: data.users[0].role,
            office: data.users[0].office,
            password: "",
            passwordAgain: ""
        }

        setValues(newData);
    }, [data]);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require")),
        email: yup.string().required(t("wrongEmail")).email(t("wrongEmail")),
        role: yup.string().required(t("Require")),
        office: yup.string().when("role", {
            is: (role) => role === "SELLER",
            then: yup.string().required("Require")
        }),
        password: yup.string()
            .when("passwordAgain", {
                is: (passwordAgain) => passwordAgain.length > 0,
                then: yup.string().required("passwordsMustMatch")
            }),
        passwordAgain: yup.string()
            .when("password", {
                is: (password) => password.length > 0,
                then: yup.string().required("passwordsMustMatch").oneOf([yup.ref('password'), null], t('passwordsMustMatch'))
            })
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const onSave = () => {
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                sendToServer();
            else
                setErrors(errors);
        });
    }
    
    const sendToServer = () => {
        let variables = {
            name: values.name,
            email: values.email,
            role: values.role,
            office: values.office
        };

        if (id && id !== "new")
            variables.id = id;

        if (values.password === values.passwordAgain)
            variables.password = values.password;

        saveUser({variables})
            .then(() => {
                history.push("/users");
            }).catch(e => {
            setErrors({password: t(e.message)})});
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <>
            <h1>User</h1>
            <div>
                <form>
                <Input
                    name="name"
                    placeholder={t('Name')}
                    {...regField}
                    />
                <Input
                    placeholder={t('E-mail')}
                    name="email"
                    autocomplete="e-mail"
                    dissabled={false}
                    {...regField}
                    />

                <br /><br />
                <Input
                    placeholder={t('Password')}
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    dissabled={false}
                    {...regField}
                />
                <Input
                    placeholder={t('Password again')}
                    type="password"
                    autocomplete="new-password"
                    name="passwordAgain"
                    dissabled={false}
                    {...regField}
                />

                <br /><br />

                <Select
                    placeholder={t('Role')}
                    name="role"
                    options={data.roles}
                    {...regField}
                    />
                {values.role === "seller" &&
                <Select
                    placeholder={t('Office')}
                    name="office"
                    options={data.offices}
                    {...regField}
                />
                }
                </form>
                <div>
                    <Button onClick={onSave} type="primary">{t('Save')}</Button>
                    <Button onClick={() => history.push('/users')} type="secondary">{t('Cancel')}</Button>
                </div>
            </div>
        </>
    );
}