import React from 'react'

const Home = (props) => {
  return (
    <div style={{textAlign:'center'}}>
        <img src="/calendar.svg" style={{textAlign: "center", margin: "70px 0"}} alt="" />
    </div>
  );
};

export default Home;