import React from 'react';
import {default as ReactSwitch} from 'react-switch';

const Switch = (props) => {

    const material = {
        onColor: "#86d3ff",
        onHandleColor: "#2693e6",
        handleDiameter: 25,
        uncheckedIcon: false,
        checkedIcon: false,
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.6)",
        activeBoxShadow: "0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
        height: 20,
        width: 48
    }

    const view = props.default ? material : {};

    return (
        <ReactSwitch
            disabled={props.disabled}
            onChange={props.onChange}
            checked={props.checked}
            {...view}
        />
    );
};

Switch.propTypes = {};
Switch.defaultProps = {
    onChange: () => {
    },
    checked: false,
    default: true
};

export default Switch;