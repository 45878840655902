import React, {useEffect, useRef, useState} from 'react';
import {useOrdersInPeriod, useSort} from '../../../../appolo/order.queries';
import {useTranslation} from 'react-i18next';
import {add, endOfWeek, getISOWeek} from 'date-fns';
import classes from '../../Orders/Orders.module.scss';
import reportClasses from '../Reports.module.scss';
import cn from 'classnames';
import {DateFromWeekNum, FormatDate, FormatIsoDate, ReadDate, WeekNumber} from '../../../../utils/date';
import {useReactToPrint} from 'react-to-print';
import {ComponentToPrint} from '../../../UI/Printer/Printer';
import Button from '../../../UI/Button/Button';
import {useHistory, useParams} from 'react-router-dom';
import EmployeeForm from './Forms/EmployeeForm';

const WeekReport = (props) => {
    const {t} = useTranslation();
    let history = useHistory();
    let {date} = useParams();

    const [orders, {data, loading, error}] = useOrdersInPeriod();
    const {data: sortData} = useSort();
    const printComponentRef = useRef();
    const [sortOrder, setSortOrder] = useState([]);
    const [weekStartDate, setWeekStartDate] = useState(ReadDate(date));

    useEffect(() => {
        if (weekStartDate) orders({variables: {from: weekStartDate, till: add(weekStartDate, {days: 7})}});
    }, [weekStartDate]);

    useEffect(() => {
        if (sortData) setSortOrder(sortData.offices.map(x => x.code));
    }, [sortData]);

    const goBack = () => {
        const prevWeek = add(weekStartDate, {days: -7});
        setWeekStartDate(prevWeek);
        history.push(`/reports/art/${FormatDate(prevWeek)}`);
    }

    const goNext = () => {
        const nextWeek = add(weekStartDate, {days: 7});
        setWeekStartDate(nextWeek);
        history.push(`/reports/art/${FormatDate(nextWeek)}`);
    }

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
    });

    const ThisWeak = (data) => {
        const filtered = data.data.filter(x => x.weekNumber === getISOWeek(weekStartDate) && x.completed  !== 'CANCELED');
        const weekStart = DateFromWeekNum(WeekNumber(weekStartDate), weekStartDate);
        const weekEnd = endOfWeek(weekStart, {weekStartsOn: 1});

        return (
            <div className={classes.report}>
                <br/>
                <h2>{FormatDate(weekStart)} - {FormatDate(weekEnd)}</h2>
                <ul className={classes.list}>
                    {<li className={cn(classes.title, classes.listItem, reportClasses.row)}
                         style={{gridTemplateColumns: `100px 130px 1fr 100px 120px 120px 100px`}}>
                        <div>{t("Number")}</div>
                        <div>{t("To date")}</div>
                        <div>{t("Title")}</div>
                        <div>{t("Employee")}</div>
                        <div>{t("Note 1")}</div>
                        <div>{t("Note 2")}</div>
                        <div>{t("Status")}</div>
                    </li>}
                    {filtered.sort(comparer).map(x => {
                        const data = x.data ? JSON.parse(x.data) : [];

                        const monument = data?.find(x => x.id === "monument") ?? "";
                        const portrait = data?.find(x => x.id === "portrait") ?? "";

                        let title = monument?.value ?? "";
                        title += title.length > 0 ? ", ": "";
                        title += portrait?.value ? `Portrait: ${portrait?.value}` : "";

                        if (title.length === 0) {
                            title = t("No data added");
                        }

                        return (
                            <li key={x.id} className={cn(classes.item,
                                classes.listItem,
                                reportClasses.row,
                                {[classes.completed]: x.completed === 'COMPLETED'},
                                {[classes.inProgress]: x.completed === 'IN_PROGRESS'},
                                {[classes.canceled]: x.completed === 'CANCELED'})}
                                style={{gridTemplateColumns: `100px 130px 1fr 100px 120px 120px 100px`}}
                            >
                                <div>{x.number}</div>
                                <div>{FormatIsoDate(x.shouldCompletedAt)}</div>
                                <div>{title}</div>
                                <div className={reportClasses.column}>
                                    <EmployeeForm type="update-item" order={x}>{x.employee !== "" ? x.employee : " - "}</EmployeeForm>
                                </div>
                                <div></div>
                                <div className={reportClasses.column}></div>
                                <div>{t(x.completed)}</div>
                            </li>
                        );
                    })}

                    {filtered.length === 0 && <li className={classes.noRecords}>{t('No orders found...')} <span role="img" aria-label="find">🔎</span></li>}
                </ul>
            </div>
        );
    };

    const comparer = (a, b) => {
        if (getOffice(a.number) < getOffice(b.number)) {
            return -1;
        }
        if (getOffice(a.number) < getOffice(b.number)) {
            return 1;
        }
        return 0;
    };



    const getOffice = (item) => {
        const toEnd = 9999999;
        const dash = item.indexOf('-');

        if (dash === -1)
            return toEnd;

        const sorting = item.split('-');

        const office = sorting[0];
        const index = sortOrder.indexOf(office.toString());

        const result = index !== -1 ? +index  + "" + sorting[1] : toEnd;

        return result;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <div>
            <div>
                <span onClick={() => history.push("/reports/week")} className={classes.link}>{t("Week report")}</span> &nbsp;
                <span onClick={() => history.push("/reports/art")}  className={classes.link}>{t("Art report")}</span>
            </div>
            <div className={reportClasses.header}>
                <h1>{t("Art report")}</h1>
                <Button onClick={goBack}>&lt;</Button>
                <Button onClick={goNext}>&gt;</Button>
                <Button onClick={handlePrint}>{t('Print')}</Button>
            </div>
            <ComponentToPrint ref={printComponentRef}>
                {data ? <ThisWeak data={data.ordersInPeriod}/> : undefined}
            </ComponentToPrint>
        </div>
    );
};

WeekReport.propTypes = {};
WeekReport.defaultProps = {};

export default WeekReport;
