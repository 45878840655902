import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            "Orders": "Orders",
            "Order": "Order",
            "Order number": "Order number",
            "Description": "Description",
            "Order type": "Order type",
            "Order completion date": "Order completion date",
            "View": "View",
            "Edit": "Edit",
            "Comment": "Comment",
            "Employee": "Employee",
            "Status": "Status",
            "Save": "Save",
            "Cancel": "Cancel",
            "WAITING": "Waiting",
            "IN_PROGRESS": "In progress",
            "COMPLETED": "Completed",
            "CANCELED": "Canceled",
            "Quick order": "Quick order",
            "Complex order": "Complex order",
            "Monument": "Monument",
            "Portrait": "Portrait",
            "Add order": "Add order",
            "seller": "Seller",
            "manager": "Manager",
            "admin": "Admin",
            "Name": "Name",
            "E-mail": "E-mail",
            "Role": "Role",
            "Users": "Users",
            "Offices": "Offices",
            "Logout": "Logout",
            "Login": "Login",
            "New order": "New order",
            "Reports": "Reports",
            "Number": "Number",
            "To date": "To date",
            "Title": "Title",
            "Expired": "Expired",
            "This week": "This week",
            "No data added": "No data added",
            "No orders found...": "No orders found...",
            "Week report": "Week report",
            "Print": "Распечатать",
            "Password": "Password",
            "Password again": "Password again",
            "Add office": "Add office",
            "Code": "Code",
            "Address": "Address",
            "Sort number": "Sort number",
            "Add user": "Add user",
            "Delete": "Delete",
            "No expired orders found": "No expired orders found",
            "List": "List",
            "Grid": "Grid",
            "All offices": "All offices",
            "Other": "Other",
            "Art": "Art",
            "Visualisation": "Visual.",
            "Materials": "Materials",
            "Add material": "Add material",
            "None": "None",
            "Low": "Low",
            "Medium": "Medium",
            "High": "High",
            "Order phone": "Order phone",
            "Phone not from Latvia": "Phone not from Latvia"
        }
    },
    ru: {
        translation: {
            "Orders": "Заказы",
            "Order": "Заказ",
            "Order number": "Номер заказа",
            "Description": "Описание",
            "Order type": "Тип заказа",
            "Order completion date": "Дата сдачи заказа",
            "View": "Просмотр",
            "Edit": "Редактировать",
            "Comment": "Комментарии",
            "Employee": "Исполнитель",
            "Status": "Статус",
            "Save": "Сохранить",
            "Cancel": "Отменить",
            "WAITING": "В ожидании",
            "IN_PROGRESS": "В работе",
            "COMPLETED": "Выполнен",
            "CANCELED": "Отменен",
            "Quick order": "Быстрый заказ",
            "Complex order": "Комплексный",
            "Monument": "Памятник",
            "Portrait": "Портрет",
            "Add order": "Новый заказ",
            "seller": "Агент",
            "manager": "Менеджер",
            "admin": "Администратор",
            "Name": "Имя",
            "E-mail": "Е-маил",
            "Role": "Роль",
            "Users": "Пользователи",
            "Offices": "Бюро",
            "Logout": "Выйти",
            "Login": "Войти",
            "New order": "Новый заказ",
            "Reports": "Отчеты",
            "Number": "Номер",
            "To date": "К дате",
            "Title": "Название",
            "Expired": "Просроченный",
            "This week": "На этой неделе",
            "No data added": "Нет данных",
            "No orders found...": "Заказов не найдено...",
            "Week report": "Недельный отчет",
            "Print": "Распечатать",
            "Password": "Пароль",
            "Password again": "Пароль еще раз",
            "Add office": "Добавить бюро",
            "Code": "Код",
            "Address": "Адрес",
            "Sort number": "Сортировка",
            "Add user": "Добавить пользователя",
            "Delete": "Удалить",
            "No expired orders found": "Просроченные заказы не найдены",
            "List": "Список",
            "Grid": "Таблица",
            "All offices": "Все офисы",
            "Other": "Другое",
            "Art": "Оформ.",
            "Visualisation": "Визуал.",
            "Materials": "Материалы",
            "Add material": "Добавить материал",
            "None": "Нет",
            "Low": "Мало",
            "Medium": "Средне",
            "High": "Много",
            "Order phone": "Телефон",
            "Phone not from Latvia": "Телефон не из Латвии"
        }
    },
    lv: {
        translation: {
            "Orders": "Pasūtījumi",
            "Order": "Pasūtījums",
            "Order number": "Pasūtījuma numurs",
            "Description": "Apraksts",
            "Order type": "Pasūtījuma veids",
            "Order completion date": "Pasūtījuma izgatavošanas datums",
            "View": "Apskats",
            "Edit": "Koriģēt",
            "Comment": "Komentāri",
            "Employee": "Izpildītājs",
            "Status": "Statuss",
            "Save": "Saglabāt",
            "Cancel": "Atsaukt",
            "WAITING": "Gaida",
            "IN_PROGRESS": "Aizņemts darbā",
            "COMPLETED": "Izpildīts",
            "CANCELED": "Atsaukts",
            "Quick order": "Ātrs pasūtījums",
            "Complex order": "Komplekss pasūtījums",
            "Monument": "Piemineklis",
            "Portrait": "Portrets",
            "Add order": "Jauns pasūtījums",
            "seller": "Aģents",
            "manager": "Menedžeris",
            "admin": "Administrators",
            "Name": "Vārds",
            "E-mail": "E-pasts",
            "Role": "Loma",
            "Users": "Lietotāji",
            "Offices": "Birojs",
            "Logout": "Iziet",
            "Login": "Ieiet",
            "New order": "Jauns pasūtījums",
            "Reports": "Atskaites",
            "Number": "Numurs",
            "To date": "Pie datuma",
            "Title": "Nosaukums",
            "Expired": "Nokavēts termiņš",
            "This week": "Šajā nedēļā",
            "No data added": "Nav datu",
            "No orders found...": "Pasūtījumi nav atrasti...",
            "Week report": "Nedēļas atskaite",
            "Print": "Izdrukāt",
            "Password": "Parole",
            "Password again": "Atkārtot paroli",
            "Add office": "Pievienot biroju",
            "Code": "Kods",
            "Address": "Adrese",
            "Sort number": "Šķirošana",
            "Add user": "Pievienot lietotāju",
            "Delete": "Dzēst",
            "No expired orders found": "Nokavēti pasūtījumi nav atrasti",
            "List": "Saraksts",
            "Grid": "Režģis",
            "All offices": "Visi biroji",
            "Other": "Cits",
            "Art": "Noform.",
            "Visualisation": "Visual.",
            "Materials": "Materiāli",
            "Add material": "Pievienojiet materiālu",
            "None": "Nav",
            "Low": "Mazs",
            "Medium": "Vidējas",
            "High": "Daudz",
            "Order phone": "Tālrunis",
            "Phone not from Latvia": "Telefons nav no Latvijas"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "lv", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
