import React, {useEffect, useState} from 'react';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import classes from './Order.module.scss';
import Select from '../../../UI/Select/Select';
import Textarea from '../../../UI/Textarea/Textarea';
import {useDeleteOrder, useNewOrder, useOrder, useSaveOrder} from '../../../../appolo/order.queries';
import {FormatIsoDate, isOneDayOld, ParseDate} from '../../../../utils/date';
import SwitchTabs from '../../../UI/SwitchTabs/SwitchTabs';
import Switch from '../../../UI/Switch/Switch';
import Calendar from '../../../UI/Calendar/Calendar';
import {format, parseISO} from 'date-fns';
import {OrderFields} from './Settings';
import {newGuid} from '../../../../utils/utils';
import Checkbox from "../../../UI/Checkbox/Checkbox";

export const Order = (props) => {
    const {t} = useTranslation();
    let history = useHistory();
    let {id} = useParams();

    const [isNewOrder, setInNewOrder] = useState(id === 'new');
    const [newOrder, newOrderData] = useNewOrder();
    const [order, orderData] = useOrder();

    const [canDelete, setCanDelete] = useState(props.role === 'admin' || props.role === 'manager');
    const [values, setValues] = useState({orderType: "QUICK"});
    const [errors, setErrors] = useState({});
    const [tab, setTab] = useState('view');
    const [orderPossibleDate, setOrderPossibleDate] = useState(new Date());
    const [disabledDates, setDisabledDates] = useState([]);
    const [hidePhone, setHidePhone] = useState(false);

    const [saveOrder] = useSaveOrder();
    const [deleteOrder] = useDeleteOrder();

    const canEdit = (props.role === 'admin' || props.role === 'manager' || isNewOrder);

    useEffect(() => {
        isNewOrder
            ? newOrder({variables: {type: values.orderType}})
            : order({variables: {id: id}});
    }, [id, isNewOrder, newOrder, order, values.orderType]);

    useEffect(() => {
        if (!newOrderData.data)
            return;

        const data = newOrderData.data.newOrder;

        switch (values.orderType) {
            case "QUICK":
                setOrderPossibleDate(parseISO(data.quickDate));
                break;
            case "COMPLEX":
                setOrderPossibleDate(parseISO(data.complexDate));
                break;
            default:
                setOrderPossibleDate(new Date());
                break;
        }

        setDisabledDates(data.disabledDates);

        if (values.orderNumber === undefined) {
            const code = data.officeCode ? data.officeCode + "-" : "";
            setValues({...values, orderNumber: code});
        }

    }, [values, newOrderData.data])

    useEffect(() => {
        if (orderData.data) {
            const data = orderData.data?.orders[0];

            const newData = {
                orderType: data?.type,
                orderNumber: data?.number,
                shouldCompletedAt: FormatIsoDate(data?.shouldCompletedAt),
                orderDescription: data?.description,
                data: data?.data === "" ? OrderFields: JSON.parse(data?.data),
                comment: data?.comment,
                phone: data?.phone ?? 0, 
                employee: data?.employee,
                completed: data?.completed
            };

            setValues(newData);
            setCanDelete(props.role === 'admin' || props.role === 'manager' || (props.role === "seller" && isOneDayOld(data?.createdAt) ))

            if (newData.data.find(x => x.checked) === undefined && canEdit)
                setTab("edit");
        }

    }, [orderData, props.role, canEdit]);



    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const validationRules = {
        orderNumber: yup.string().required(t('Require')).matches(
            /^[0-9]+[-][0-9]+[-][0-9]+$/,
            "Please check order number. Example: 2-100-23. 2 - place code, 100 - order number, 23 - year"
        ),
        phone: yup.string().when([], {
            is: () => hidePhone === false,
            then: yup.string().required(t('Require')).matches(
                /^\d{8}$/,
                "Please check phone number."
            ),
            otherwise: yup.string().notRequired()
        }),
        orderType: yup.string().required(t('Require')),
        shouldCompletedAt: yup.string().required(t('Require')),
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors,
        disabled: !canEdit
    };

    const onCalendarDateChange = (date) => {
        const event = {
            target: {
                name: "shouldCompletedAt",
                value: format(date, 'dd.MM.yyyy')
            },
        };
        fieldChanged(event);
    }

    const onOrderSettingChange = (item) => {
        const newValues = {...values};
        const setting = newValues.data.find((x) => x.id === item.id);
        setting.checked = !setting.checked;
        setValues(newValues);
    }

    const onOrderSettingCompleted = (item) => {
        const newValues = {...values};
        const setting = newValues.data.find((x) => x.id === item.id);
        setting.completed = !setting.completed;
        newValues.completed = calcStatus();
        setValues(newValues);
    }

    const onOrderSettingTextChange = (item, event) => {
        const newValues = {...values};
        const setting = newValues.data.find((x) => x.id === item.id);

        if (setting.checked)
            setting.value = event.target.value;

        setValues(newValues);
    }

    const createTitle = () => {
        let title = "";

        if (!Array.isArray(values.data)) return t("No data added");

        values.data.forEach((x) => {
            const val = x.value ?  " " + x.value : "";
            title += x.checked ? x.name + val + ", " : "";
        });
        title = title.replace(/, $/, '');

        return title !== "" ? title : t("No data added");
    };

    const calcStatus = () => {
        if (values.completed === 'CANCELED') return 'CANCELED';

        const selected = values.data.filter(x => (x.checked));
        const completed = values.data.filter(x => (x.completed));

        if (completed.length === 0) return 'WAITING';
        if (completed.length === selected.length) return 'COMPLETED';

        return 'IN_PROGRESS';
    }

    const onSave = () => {
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                sendToServer();
            else
                setErrors(errors);
        });
    };

    const sendToServer = () => {
        const variables = {
            input: {
                id: newGuid(),
                type: values.orderType,
                number: values.orderNumber,
                shouldCompletedAt: ParseDate(values.shouldCompletedAt),
                description: values.orderDescription,
                title: createTitle(),
                phone: hidePhone ? 0 : +values.phone,
                data: "",
                completed: "WAITING",
                comment: "",
                employee: ""
            }
        };

        if (!isNewOrder) {
            variables.input.id = id;
            variables.input.title = createTitle();
            variables.input.data = JSON.stringify(values.data);
            variables.input.completed = calcStatus();
            variables.input.comment = values.comment;
            variables.input.employee = values.employee;
        }

        saveOrder({variables: variables})
            .then((res) => {
                if (!res.errors) {
                    setInNewOrder(false);
                    if (props.role !== 'admin' && props.role !== 'manager') {
                        history.push('/orders');
                    } else {

                        history.push('/orders/' + variables.input.id);
                    }
                } else {
                    console.log("then",res);
                }
            }).catch(e => {
                setErrors({orderNumber: t(e.message)});
         });
    };

    const onDelete = () => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm(t("Delete this order?")))
            return;

        const variables = {
            id: id
        };

        deleteOrder({variables: variables}).then(() => {
            history.push('/orders');
        })
    }



    if (!isNewOrder && orderData.loading) return (<div>Load order...</div>);
    if (!isNewOrder && orderData.error) return (<div>Error. Order not loaded...</div>);
    
    return (
        <div>
            <h1>{t("Order")}</h1>

            {isNewOrder && newOrderData.loading && <div>Calculate order date...</div>}
            {isNewOrder && newOrderData.error && <div>Error. Order date not calculated...</div>}

            {isNewOrder && newOrderData.data &&
            <>
                <Input
                    name="orderNumber"
                    label={t('Order number')}
                    placeholder={t('Order number')}
                    {...regField}
                />

                {hidePhone === false ? <Input
                    name="phone"
                    label={t('Order phone')}
                    placeholder={t('Order phone')}
                    {...regField}
                /> : null }
                
                <Checkbox
                    name="phoneNotFromLatvia"
                    label={t('Phone not from Latvia')}
                    onChange={() => setHidePhone(!hidePhone)}
                />

                <Select
                    placeholder={t('Order type')}
                    label={t('Order type')}
                    name="orderType"
                    options={props.orderTypes}
                    {...regField}
                />

                <Textarea
                    placeholder={t('Description')}
                    label={t('Description')}
                    name="orderDescription"
                    {...regField}
                />

                <Calendar
                    onChange={onCalendarDateChange}
                    disabledDates={disabledDates}
                    minDate={orderPossibleDate} />

                { errors.shouldCompletedAt && <div className={classes.error}>{t("Please select date.")}</div>}

            </>
            }

            {!isNewOrder &&
            <>
                <div className={classes.threeColumns}>
                    <Input
                        name="orderNumber"
                        label={t('Order number')}
                        placeholder={t('Order number')}
                        {...regField}
                    />

                    <Select
                        placeholder={t('Order type')}
                        label={t('Order type')}
                        name="orderType"
                        options={props.orderTypes}
                        {...regField}
                    />

                    <Input
                        name="shouldCompletedAt"
                        label={t('Order completion date')}
                        placeholder={t('Order date')}
                        {...regField}
                    />
                </div>

                <Textarea
                    placeholder={t('Description')}
                    label={t('Description')}
                    name="orderDescription"
                    {...regField}
                />

                {canEdit && <SwitchTabs
                    tabs={[{label: t('View'), value: 'view'}, {label: t('Edit'), value: 'edit'}]}
                    selected={tab}
                    onSwitch={(x) => setTab(x)}
                />}

                {tab === 'view' && <>
                    <div className={classes.order}>
                        <h2>{t("Order")}: {values.orderNumber}</h2>
                        <div className={classes.orderDate}>{values.shouldCompletedAt}</div>
                        <ol>
                            {  values.data && values.data.map(x => {

                                if (x.checked) return (
                                        <li key={x.id}>
                                            <div className={classes.viewRow}>
                                                <div className={classes.dashed}>{x.name}: {x.value}</div>
                                                <Switch disabled={!canEdit} checked={x.completed} onChange={() => onOrderSettingCompleted(x)} />
                                            </div>
                                        </li>
                                );
                                return null;
                            })
                            }
                        </ol>
                        {values.data?.filter(x => x.checked).length === 0  && <div>{t("No data added")}</div>}
                    </div>

                    {canEdit && <>
                        <Textarea
                            placeholder={t('Comment')}
                            label={t('Comment')}
                            name="comment"
                            {...regField}
                        />

                        <Input
                            name="employee"
                            label={t('Employee')}
                            placeholder={t('Employee')}
                            {...regField}
                        />

                    </>}

                    <Select
                        placeholder={t('Status')}
                        label={t('Status')}
                        name="completed"
                        options={props.completed}
                        {...regField}
                    />

                </>}

                {tab === 'edit' && <>
                    {
                        values.data.map(x => {
                            const itemName = `setting-${x.id}`;
                            const itemValue = x.checked ? {[itemName]: x.value} : {[itemName]: ""};

                            return (
                            <div key={x.id} className={classes.editRow}>
                                <Switch checked={x.checked} onChange={() => onOrderSettingChange(x)} />
                                {t(x.name)}
                                <Input name={itemName} value={itemValue} onChange={(e) => onOrderSettingTextChange(x, e)} style={{margin: 0}} />
                            </div>
                        )})
                    }
                </>}
            </>
            }

            <div className={classes.buttons}>
                <div>{canDelete && <Button onClick={onDelete} type="secondary">{t('Delete')}</Button>}</div>
                {canEdit && <Button onClick={onSave} type="primary">{t('Save')}</Button>}
                <Button onClick={() => history.push('/orders')} type="secondary">{canEdit ? t('Cancel') : t('Back')}</Button>
            </div>
        </div>
    );
};

Order.propTypes = {};
Order.defaultProps = {
    orderTypes: [
        {label: 'Quick order', value: 'QUICK'},
        {label: 'Complex order', value: 'COMPLEX'}
    ],
    completed: [
        {label: 'WAITING', value: 'WAITING'},
        {label: 'IN_PROGRESS', value: 'IN_PROGRESS'},
        {label: 'COMPLETED', value: 'COMPLETED'},
        {label: 'CANCELED', value: 'CANCELED'},
    ]
};
