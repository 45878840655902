import React from "react";
import {useOffices} from "../../../appolo/admin.queries";
import Table from "../../UI/Table/Table";
import { useHistory } from "react-router-dom";
import Button from "../../UI/Button/Button";
import classes from "./Offices.module.scss";
import {useTranslation} from 'react-i18next';

export const Offices = (props) => {
    let history = useHistory();
    const {t} = useTranslation();
    const { loading, error, data} = useOffices();

    if (props.role !== "admin") {
        history.push("/");
    }

    const onClick = (data) => {
        history.push(`/offices/${data.id}`);
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <>
        <h1>{t("Offices")}</h1>
        <div className={classes.panel}>
            <Button type="secondary" onClick={() => history.push("/offices/new")} >{t("Add office")}</Button>
        </div>
        <Table
            data={data.offices}
            columns={[
                {
                    Header: t('Name'),
                    accessor: 'name'
                },
                {
                    Header: t('Code'),
                    accessor: 'code'
                },
                {
                    Header: t('Address'),
                    accessor: 'address',
                },
                {
                    Header: t('Sort number'),
                    accessor: 'sortNumber'
                }
            ]}
            onRowClick={onClick}
            />
        </>
    );
}