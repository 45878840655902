import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useOrders} from '../../../appolo/order.queries';
import {OrderFields} from '../Orders/Order/Settings';
import {endOfWeek, getYear, parseISO} from 'date-fns';
import {DateFromWeekNum, FormatDate, FormatIsoDate} from '../../../utils/date';
import classes from '../Orders/Orders.module.scss';
import cn from 'classnames';
import Tooltip from '../../UI/Tooltip/Tooltip';

const Archive = (props) => {
    const {t} = useTranslation();
    let history = useHistory();

    const [tab, setTab] = useState(props.role === 'manager' ? 'grid': 'list');
    const [sortOrder, setSortOrder] = useState([]);

    const {loading, error, data} = useOrders();

    if (props.role !== "admin" && props.role !== "manager") {
        history.push("/");
    }

    const Header = () => {
        const gridLength = tab === "grid" ? OrderFields.filter(x => x.showOnGrid).length : 1;
        const gridWidth = tab === "grid" ? 60 : 100;

        return (<li className={cn(classes.title, classes.sticky, {[classes.listItem]:tab === "list"}, {[classes.gridItem]:tab === "grid"})}
                    style={{gridTemplateColumns: `100px 100px 1fr repeat(${gridLength}, ${gridWidth}px)`}}>
                <div>{t("Number")}</div>
                <div>{t("To date")}</div>
                {tab === "list" && <div>{t("Title")}</div>}
                {tab === "list" && <div>{t("Status")}</div>}

                {tab === "grid" && <div></div>}
                {tab === "grid" && OrderFields
                    .filter(g => g.showOnGrid).map(g => <div key={g.id}>{t(g.name)}</div>)}
            </li>
        )};

    function compareDates(a, b) {
        if (a.date < b.date) {
            return -1;
        }
        if (a.date > b.date) {
            return 1;
        }

        return 0;
    }

    const getOffice = (item) => {
        const toEnd = 9999999;
        const dash = item.indexOf('-');

        if (dash === -1)
            return toEnd;

        const sorting = item.split('-');

        const office = sorting[0];
        const index = sortOrder.indexOf(office.toString());

        const result = index !== -1 ? +index  + "" + sorting[1] : toEnd;

        return result;
    };

    const getStatus = (item) => {
        const status = item.status !== undefined ? item.status : "WAITING";
        return <div className={cn(classes.dot,
            {[classes.statusWaiting]: status === "WAITING"},
            {[classes.statusInProgress]: status === "IN_PROGRESS"},
            {[classes.statusCompleted]: status === "COMPLETED"},
            {[classes.statusExpired]: status === "EXPIRED"})} />
    }

    const comparer = (a, b) => {
        if (getOffice(a.number) < getOffice(b.number)) {
            return -1;
        }
        if (getOffice(a.number) < getOffice(b.number)) {
            return 1;
        }
        return 0;
    };

    const onClick = (data, newWindow = false) => {
        if (newWindow)
            window.open(`/orders/${data.id}`, "_blank");
        else
            history.push(`/orders/${data.id}`);
    };

    const Row = ({data:x}) => {

        const orderData = tab === "grid" && x.data && x.data !== ""
            ? JSON.parse(x.data).reduce((a, v) => ({ ...a, [v.id]: v}), {}) : {};

        return (
            <>
                {tab === "list" && <div onClick={() => onClick(x, true)}>{x.number}</div>}
                {tab === "grid" && <div onClick={() => onClick(x, true)}><Tooltip type="update-order" order={x} content={x}>{x.number}</Tooltip></div>}
                <div  onClick={() => onClick(x)}>{FormatIsoDate(x.shouldCompletedAt)}</div>

                {tab === "list" && <div onClick={() => onClick(x)}>{x.title}</div> }
                {tab === "list" && <div>{t(x.completed)}</div> }

                {tab === "grid" && <div onClick={() => onClick(x)} className={classes.newItem}></div> }

                {tab === "grid" && OrderFields.filter(g => g.showOnGrid).map(g =>
                    <div key={`${g.id}-${x.id}`}>
                        {orderData[g.id] !== undefined && orderData[g.id].checked
                            ? <Tooltip type="update-item" order={x} content={orderData[g.id]}>{getStatus(orderData[g.id])}</Tooltip>
                            : <div className={classes.newItem} onClick={() => {}} />
                        }
                    </div>)}
            </>
        )
    }

    const NextWeeks = (data) => {
        const gridLength = tab === "grid" ? OrderFields.filter(x => x.showOnGrid).length : 1;
        const gridWidth = tab === "grid" ? 60 : 100;

        const endOfThisWeek = endOfWeek(new Date(), {weekStartsOn: 1});
        const filtered = data.data;

        const weeks = [];
        filtered.forEach(x => {
            if (weeks.filter(f => f.weekNumber === x.weekNumber && f.year === x.year).length === 0) weeks.push({weekNumber: x.weekNumber, year: x.year, date: x.shouldCompletedAt});
        });

        const sortedWeeks = weeks.sort(compareDates);

        return (
            <>
                {sortedWeeks.map(x => {
                    const weekStart = DateFromWeekNum(x.weekNumber, parseISO(x.date));
                    const weekEnd = endOfWeek(weekStart, {weekStartsOn: 1});
                    const year = getYear(weekStart);

                    const data = filtered.filter(y => y.weekNumber === x.weekNumber && year === y.year);

                    return (
                        <div key={`week-${x.weekNumber}`}>
                            <h2>{FormatDate(weekStart)} - {FormatDate(weekEnd)}</h2>
                            <ul className={classes.list}>
                                <Header />
                                {data.sort(comparer).map(weekData => (
                                    <li key={`week-item-${weekData.id}`} className={cn(classes.item,
                                        {[classes.completed]: weekData.completed === 'COMPLETED'},
                                        {[classes.inProgress]: weekData.completed === 'IN_PROGRESS'},
                                        {[classes.canceled]: weekData.completed === 'CANCELED'},
                                        {[classes.listItem]:tab === "list"},
                                        {[classes.gridItem]:tab === "grid"})}
                                        style={{gridTemplateColumns: `100px 100px 1fr repeat(${gridLength}, ${gridWidth}px)`}}
                                    >

                                        <Row data={weekData} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </>
        );
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <div>
            <h1>{t('Archive')}</h1>

            <NextWeeks data={data.orders} />
        </div>
    );
};

Archive.defaultProps = {};

export default Archive;
