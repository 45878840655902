import React, {useEffect, useState} from 'react';
import {Calendar as ReactCalendar} from 'react-calendar';
import classes from './Calendar.module.scss';
import {useTranslation} from 'react-i18next';
import {isEqual, parseISO} from 'date-fns';
import {FormatDate, FormatIsoDate, FormatIsoDateWithOutTimeZone, isWeekend} from '../../../utils/date';

const Calendar = (props) => {
    const {i18n} = useTranslation();
    const [locale, setLocale] = useState("en-EN");
    const [value, setValue] = useState(null);
    const [minDate, setMinDate] = useState(new Date());
    const [disabledDates, setDisabledDates] = useState([]);

    useEffect(() => {
        const getLocale = () => {
            switch (i18n.language) {
                case "lv":
                    return "lv-LV";
                case "ru":
                    return "ru-RU";
                default:
                    return "en-EN"
            }
        };

        setLocale(getLocale);
    }, [i18n.language]);

    useEffect(() => {
        if (FormatDate(minDate) !== FormatDate(props.minDate))
        {
            setMinDate(props.minDate);
            setValue(null);
        }
    }, [props.minDate, minDate]);

    useEffect(() => {
        const dates = props.disabledDates.map(x => FormatIsoDateWithOutTimeZone(x));
        setDisabledDates(dates);
    }, [props.disabledDates]);

    const onChange = (date) => {
        setValue(date);
        props.onChange(date);
    }

    return (
        <ReactCalendar
            tileClassName={classes.tile}
            defaultActiveStartDate={minDate}
            value={value}
            minDate={minDate}
            onChange={onChange}
            locale={locale}
            tileDisabled={({date, view}) => {
                return isWeekend(date) || disabledDates.includes(FormatDate(date));
            }}
        />
    );
};

Calendar.defaultProps = {
    minDate: new Date(),
    onChange: () => {},
    disabledDates: []
};

export default Calendar;
