export const MaterialFields = [
    {
        id: "2",
        name: "2",
        value: "none"
    },
    {
        id: "3",
        name: "3",
        value: "none"
    },
    {
        id: "5",
        name: "5",
        value: "none"
    },
    {
        id: "6",
        name: "6",
        value: "none"
    },
    {
        id: "7",
        name: "7",
        value: "none"
    },
    {

        id: "10",
        name: "10",
        value: "none"
    },
    {

        id: "12",
        name: "12",
        value: "none"
    },
    {

        id: "15",
        name: "15",
        value: "none"
    },
    {

        id: "20",
        name: "20",
        value: "none"
    },
];
