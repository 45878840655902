import React, {useEffect, useState} from 'react';
import TooltipLight from 'react-tooltip-lite';
import classes from './Tooltip.module.scss';
import Textarea from '../Textarea/Textarea';
import Select from '../Select/Select';
import {useTranslation} from 'react-i18next';
import Button from '../Button/Button';
import {useSaveOrder} from '../../../appolo/order.queries';
import Input from '../Input/Input';

const Tooltip = (props) => {
    const useHover = props.isOpen === undefined;
    const [values, setValues] = useState({orderItemStatus: "WAITING", saved: true});
    const [saveOrder] = useSaveOrder();
    const {t} = useTranslation();

    useEffect(() => {
       const newData = {
           orderItemName: props.content.name ?? "",
           orderItemStatus: props.content.status ?? props.content.completed ?? "WAITING",
           orderItemEmployee: props.content.employee ?? "",
           orderItemValue: props.content.value ?? "",
           saved: true,
       }

       setValues(newData);
    }, [props.content]);

    const fieldChanged = (event) => {
        const newValues = {...values, saved: false};
        newValues[event.target.name] = event.target.value;
        setValues(newValues);
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
    };

    const createTitle = (order) => {
        let title = "";
        const data = JSON.parse(order.data);

        if (!Array.isArray(data)) return t("No data added");

        data.forEach((x) => {
            const val = x.value ?  " " + x.value : "";
            title += x.checked ? x.name + val + ", " : "";
        });
        title = title.replace(/, $/, '');

        return title !== "" ? title : t("No data added");
    };

    const onSave = () => {
        const order = {...props.order};

        if (props.type === "update-order") {
            order.completed = values.orderItemStatus;
        }

        if (props.type === "update-item") {
            const data = JSON.parse(order.data);
            const changes = data.find(x => x.id === props.content.id);

            changes.status = values.orderItemStatus;
            changes.employee = values.orderItemEmployee;
            changes.value = values.orderItemValue;

            if (values.orderItemStatus === "COMPLETED")
                changes.completed = true;

            order.data = JSON.stringify(data);
        }

        order.title = createTitle(order);

        updateOrder(order);
    }

    const onDelete = () => {
        const order = {...props.order};
        const data = JSON.parse(order.data);
        const changes = data.find(x => x.id === props.content.id);

        changes.checked = false;

        order.data = JSON.stringify(data);
        updateOrder(order);
    }

    const updateOrder = (order) => {
        delete order.__typename;
        delete order.createdAt;
        delete order.sortNumber;
        delete order.weekNumber;
        delete order.year;

        const variables = {
            input: order
        }

        saveOrder({variables: variables})
            .then((res) => {
                if (res.errors) {
                    console.log("save tooltip error", res);
                }
            });
    }

    const TooltipCard = ({data}) => (
        <div className={classes.body}>

            {props.type === "update-order" && <>
                <Select
                    placeholder={t('Status')}
                    label={t('Status')}
                    name="orderItemStatus"
                    options={props.orderStatus}
                    {...regField}
                />
            </>}

            {props.type === "update-item" && <>
                <div className={classes.name}>
                    {values.orderItemName}
                </div>

                <Select
                    placeholder={t('Status')}
                    label={t('Status')}
                    name="orderItemStatus"
                    options={props.status}
                    {...regField}
                />
                <Input
                    placeholder={t('Employee')}
                    label={t('Employee')}
                    name="orderItemEmployee"
                    {...regField}
                />
                <Textarea
                    placeholder={t('Comment')}
                    label={t('Comment')}
                    name="orderItemValue"
                    {...regField}
                />
            </>}
            <div className={classes.buttons}>
                {props.type === "update-order" && <div></div>}
                {props.type === "update-item" && <Button onClick={onDelete} type="secondary">x</Button>}
                <div></div>
                <Button onClick={onSave} disabled={values.saved} type="primary">{t('Save')}</Button>
            </div>
        </div>
    );

    return (
        <TooltipLight
            className={classes.Tooltip}
            useHover={useHover}
            isOpen={props.isOpen}
            direction="down-start"
            background="#fff"
            mouseOutDelay={300}
            content={TooltipCard(props.content)}>
            {props.children}
        </TooltipLight>
    );
}

Tooltip.defaultProps = {
    isOpen: undefined,
    status: [
        {label: "WAITING", value: "WAITING"},
        {label: "IN_PROGRESS", value: "IN_PROGRESS"},
        {label: "COMPLETED", value: "COMPLETED"},
        {label: "Expired", value: "EXPIRED"},
    ],
    orderStatus: [
        {label: 'WAITING', value: 'WAITING'},
        {label: 'IN_PROGRESS', value: 'IN_PROGRESS'},
        {label: 'COMPLETED', value: 'COMPLETED'},
        {label: 'CANCELED', value: 'CANCELED'},
    ]
}

export default Tooltip
