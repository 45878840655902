import React, {useState} from 'react';
import TooltipLight from 'react-tooltip-lite';
import classes from '../../../../UI/Tooltip/Tooltip.module.scss';
import {useSaveOrder} from '../../../../../appolo/order.queries';
import {useTranslation} from 'react-i18next';
import Select from '../../../../UI/Select/Select';
import {Button} from 'reactstrap';

const EmployeeForm = (props) => {
    const useHover = props.isOpen === undefined;
    const [values, setValues] = useState({employee: props.order.employee, saved: true});
    const [saveOrder] = useSaveOrder();
    const {t} = useTranslation();

    const fieldChanged = (event) => {
        const newValues = {...values, saved: false};
        newValues[event.target.name] = event.target.value;
        setValues(newValues);
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
    };

    const onSave = () => {
        const order = {...props.order};
        order.employee = values.employee;
        updateOrder(order);
    }


    const updateOrder = (order) => {
        delete order.__typename;
        delete order.createdAt;
        delete order.sortNumber;
        delete order.weekNumber;
        delete order.year;

        const variables = {
            input: order
        }

        saveOrder({variables: variables})
            .then((res) => {
                if (res.errors) {
                    console.log("save tooltip error", res);
                }
            });
    }

    const TooltipCard = () => (
        <div className={classes.body}>

                <Select
                    placeholder={t('Employee')}
                    label={t('Employee')}
                    name="employee"
                    options={props.employee}
                    {...regField}
                />

            <div className={classes.buttons}>
                <div></div>
                <div></div>
                <Button onClick={onSave} disabled={values.saved} type="primary">{t('Save')}</Button>
            </div>
        </div>
    );

    return (
        <TooltipLight
            className={classes.Tooltip}
            useHover={useHover}
            isOpen={props.isOpen}
            direction="down-start"
            background="#fff"
            mouseOutDelay={300}
            content={TooltipCard(props.content)}>
            {props.children}
        </TooltipLight>
    );
}

EmployeeForm.defaultProps = {
    isOpen: undefined,
    employee: [
        {label: "Vlad", value: "Vlad"},
        {label: "Igor", value: "Igor"},
    ],
}

export default EmployeeForm
