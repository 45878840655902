import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useCommentsLazy, useOrders, useSaveComment, useSaveOrder, useSort} from '../../../appolo/order.queries';
import {DateFromWeekNum, FormatDate, FormatIsoDate} from '../../../utils/date';
import {useTranslation} from 'react-i18next';
import classes from './Orders.module.scss';
import {endOfWeek, getISOWeek, getYear, parseISO, startOfWeek} from 'date-fns';
import cn from 'classnames';
import Button from '../../UI/Button/Button';
import Select from '../../UI/Select/Select';
import SwitchTabs from '../../UI/SwitchTabs/SwitchTabs';
import {OrderFields} from './Order/Settings';
import Tooltip from '../../UI/Tooltip/Tooltip';

export const Orders = (props) => {
    const {t} = useTranslation();
    let history = useHistory();

    const {loading, error, data} = useOrders();
    const {data: sortData} = useSort();
    const [saveOrder] = useSaveOrder();
    const [saveComment] = useSaveComment();

    const [getComments, commentsData] = useCommentsLazy();

    const [tab, setTab] = useState(props.role === 'manager' ? 'grid': 'list');
    const [sortOrder, setSortOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [officeList, setOfficeList] = useState([{label: "All offices", value: "all"}]);
    const [selectedOffice, setSelectedOffice] = useState("all");


    const onClick = (data, newWindow = false) => {
        if (newWindow)
            window.open(`/orders/${data.id}`, "_blank");
        else
            history.push(`/orders/${data.id}`);
    };


    useEffect(() => {
        if (!data)
            return;

        if (selectedOffice !== "all") {
            const sortedData = data.orders.filter(f => f.number.includes(`${selectedOffice}-`));
            setOrderData(sortedData);
            return;
        }

        setOrderData(data.orders);
    }, [data, selectedOffice]);

    useEffect(() => {
        if (!sortData)
            return;

        setSortOrder(sortData.offices.map(x => x.code));
        setOfficeList([{label: "All offices", value: "all"}, ...sortData.offices.map(x => ({label: x.name, value:x.code}))]);
    }, [sortData]);

    useEffect(() => {
        getComments();
    }, []);

    const getStatus = (item) => {
        const status = item.status !== undefined ? item.status : "WAITING";
        return <div className={cn(classes.dot,
            {[classes.statusWaiting]: status === "WAITING"},
            {[classes.statusInProgress]: status === "IN_PROGRESS"},
            {[classes.statusCompleted]: status === "COMPLETED"},
            {[classes.statusExpired]: status === "EXPIRED"})} />
    }

    const Header = () => {
        const gridLength = tab === "grid" ? OrderFields.filter(x => x.showOnGrid).length : 1;
        const gridWidth = tab === "grid" ? 60 : 100;

        return (<li className={cn(classes.title, classes.sticky, {[classes.listItem]:tab === "list"}, {[classes.gridItem]:tab === "grid"})}
            style={{gridTemplateColumns: `100px 100px 1fr repeat(${gridLength}, ${gridWidth}px)`}}>
            <div>{t("Number")}</div>
            <div>{t("To date")}</div>
            {tab === "list" && <div>{t("Title")}</div>}
            {tab === "list" && <div>{t("Status")}</div>}

            {tab === "grid" && <div></div>}
            {tab === "grid" && OrderFields
                .filter(g => g.showOnGrid).map(g => <div key={g.id}>{t(g.name)}</div>)}
        </li>
    )};

    const Comment = ({week, year}) => {
        // const comment = commentsData.data.comments.filter(x => x.weekNumber === week && x.year === year);
        // return (
        //     <div>
        //         <textarea onChange={(e) => onCommentChange(e, week, year)} className={classes.comments}>{comment.text}</textarea>
        //     </div>
        // );

        return (<></>);
    }

    const onCommentChange = (e, week, year) => {
        const variables = {
            input: {
                weekNumber: week,
                year: year,
                text: e.target.value
            }
        };

        saveComment({variables: variables}).then(() => {});
    }

    const onAddItem = (order, item) => {
        const updateOrder = {...order};

        const data = updateOrder.data ? JSON.parse(updateOrder.data): [];
        const changes = data.find(x => x.id === item.id);

        if (changes === undefined) {
            const updateItem = {...item};
            updateItem.checked = true;
            data.push(updateItem);
        } else {
            changes.checked = true;
        }

        updateOrder.data = JSON.stringify(data);
        delete updateOrder.__typename;
        delete updateOrder.createdAt;
        delete updateOrder.sortNumber;
        delete updateOrder.weekNumber;
        delete updateOrder.year;

        const variables = {
            input: updateOrder
        }

        saveOrder({variables: variables})
            .then((res) => {
                if (res.errors) {
                    console.log("save tooltip error", res);
                }
            });
    }

    const Row = ({data:x}) => {

        const orderData = tab === "grid" && x.data && x.data !== ""
            ? JSON.parse(x.data).reduce((a, v) => ({ ...a, [v.id]: v}), {}) : {};

        return (
            <>
                {tab === "list" && <div onClick={() => onClick(x, true)}>{x.number}</div>}
                {tab === "grid" && <div onClick={() => onClick(x, true)}><Tooltip type="update-order" order={x} content={x}>{x.number}</Tooltip></div>}
                <div  onClick={() => onClick(x)}>{FormatIsoDate(x.shouldCompletedAt)}</div>

                {tab === "list" && <div onClick={() => onClick(x)}>{x.title}</div> }
                {tab === "list" && <div>{t(x.completed)}</div> }

                {tab === "grid" && <div onClick={() => onClick(x)} className={classes.newItem}></div> }

                {tab === "grid" && OrderFields.filter(g => g.showOnGrid).map(g =>
                    <div key={`${g.id}-${x.id}`}>
                        {orderData[g.id] !== undefined && orderData[g.id].checked
                            ? <Tooltip type="update-item" order={x} content={orderData[g.id]}>{getStatus(orderData[g.id])}</Tooltip>
                            : <div className={classes.newItem} onClick={() => onAddItem(x, g)} />
                        }
                    </div>)}
            </>
        )
    }

    const Expired = (data) => {
        const filtered = data.data.filter(x =>
            parseISO(x.shouldCompletedAt).getTime() < startOfWeek(new Date().getTime(), {weekStartsOn: 1})
            && x.completed !== 'COMPLETED'
            && x.completed !== 'CANCELED');

        const gridLength = tab === "grid" ? OrderFields.filter(x => x.showOnGrid).length : 1;
        const gridWidth = tab === "grid" ? 60 : 100;



        return (
            <>
                <h2>{t('Expired')}</h2>
                <ul className={classes.list}>
                    <Header />

                    {filtered.sort(comparer).map(x => {
                        return(
                        <li key={`expired-item-${x.id}`} className={cn( {[classes.listItem]:tab === "list"},
                            {[classes.gridItem]:tab === "grid"}, classes.item, classes.expired)}
                            style={{gridTemplateColumns: `100px 100px 1fr repeat(${gridLength}, ${gridWidth}px)`}}>

                            <Row data={x} />
                        </li>)}

                    )}

                    {filtered.length === 0 && <li className={classes.noRecords}>{t('No expired orders found')} <span role="img" aria-label="ok">👍</span></li>}
                </ul>
            </>
        );
    };

    const comparer = (a, b) => {
        if (getOffice(a.number) < getOffice(b.number)) {
            return -1;
        }
        if (getOffice(a.number) < getOffice(b.number)) {
            return 1;
        }
        return 0;
    };



    const getOffice = (item) => {
        const toEnd = 9999999;
        const dash = item.indexOf('-');

        if (dash === -1)
            return toEnd;

        const sorting = item.split('-');

        const office = sorting[0];
        const index = sortOrder.indexOf(office.toString());

        const result = index !== -1 ? +index  + "" + sorting[1] : toEnd;

        return result;
    };

    const ThisWeak = (data) => {
        const gridLength = tab === "grid" ? OrderFields.filter(x => x.showOnGrid).length : 1;
        const gridWidth = tab === "grid" ? 60 : 100;

        const week = getISOWeek(new Date());
        const year = getYear(new Date());
        const filtered = data.data.filter(x => x.weekNumber === getISOWeek(new Date()));

        return (
            <>
                <h2>{t("This week")}</h2>
                <ul className={classes.list}>
                    <Comment week={week} year={year} />
                    <Header />
                    {filtered.sort(comparer).map(x => (
                        <li key={`current-item-${x.id}`} className={cn(classes.item,
                            {[classes.completed]: x.completed === 'COMPLETED'},
                            {[classes.inProgress]: x.completed === 'IN_PROGRESS'},
                            {[classes.canceled]: x.completed === 'CANCELED'},
                            {[classes.listItem]:tab === "list"},
                            {[classes.gridItem]:tab === "grid"})}
                            style={{gridTemplateColumns: `100px 100px 1fr repeat(${gridLength}, ${gridWidth}px)`}}
                            >

                            <Row data={x} />
                        </li>
                    ))}

                    {filtered.length === 0 && <li className={classes.noRecords}>{t('No orders found...')} <span role="img" aria-label="find">🔎</span></li>}
                </ul>
            </>
        );
    };

    function compareDates(a, b) {
        if (a.date < b.date) {
            return -1;
        }
        if (a.date > b.date) {
            return 1;
        }

        return 0;
    }

    const NextWeeks = (data) => {
        const gridLength = tab === "grid" ? OrderFields.filter(x => x.showOnGrid).length : 1;
        const gridWidth = tab === "grid" ? 60 : 100;

        const endOfThisWeek = endOfWeek(new Date(), {weekStartsOn: 1});
        const filtered = data.data.filter(x => parseISO(x.shouldCompletedAt).getTime() > endOfThisWeek.getTime());

        const weeks = [];
        filtered.forEach(x => {
            if (weeks.filter(f => f.weekNumber === x.weekNumber && f.year === x.year).length === 0) weeks.push({weekNumber: x.weekNumber, year: x.year, date: x.shouldCompletedAt});
        });

        const sortedWeeks = weeks.sort(compareDates);

        return (
            <>
                {sortedWeeks.map(x => {
                    const weekStart = DateFromWeekNum(x.weekNumber, parseISO(x.date));
                    const weekEnd = endOfWeek(weekStart, {weekStartsOn: 1});
                    const year = getYear(weekStart);

                    const data = filtered.filter(y => y.weekNumber === x.weekNumber && year === y.year);

                    return (
                        <div key={`week-${x.weekNumber}`}>
                            <h2>{FormatDate(weekStart)} - {FormatDate(weekEnd)}</h2>
                            <ul className={classes.list}>
                                <Comment week={x.weekNumber} year={year} />
                                <Header />
                                {data.sort(comparer).map(weekData => (
                                    <li key={`week-item-${weekData.id}`} className={cn(classes.item,
                                        {[classes.completed]: weekData.completed === 'COMPLETED'},
                                        {[classes.inProgress]: weekData.completed === 'IN_PROGRESS'},
                                        {[classes.canceled]: weekData.completed === 'CANCELED'},
                                        {[classes.listItem]:tab === "list"},
                                        {[classes.gridItem]:tab === "grid"})}
                                        style={{gridTemplateColumns: `100px 100px 1fr repeat(${gridLength}, ${gridWidth}px)`}}
                                        >

                                        <Row data={weekData} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </>
        );
    };

    const Seller = (data) => {
        return (
            <div>
                <ul className={classes.sellerList}>
                    <li className={cn(classes.title, classes.sticky)}>
                        <div>{t("Number")}</div>
                        <div>{t("To date")}</div>
                        <div>{t("Title")}</div>
                        <div>{t("Status")}</div>
                    </li>
                    {data.data.map(x => (
                        <li key={x.id} className={cn(classes.item,
                            {[classes.completed]: x.completed === 'COMPLETED'},
                            {[classes.inProgress]: x.completed === 'IN_PROGRESS'},
                            {[classes.canceled]: x.completed === 'CANCELED'})}
                            onClick={() => onClick(x)}>

                            <div>{x.number}</div>
                            <div>{FormatIsoDate(x.shouldCompletedAt)}</div>
                            <div>{x.title}</div>
                            <div>{t(x.completed)}</div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    const goToReport = () => {
        history.push("/reports/week");
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <div>
            <h1>{t('Orders')}</h1>

            <div className={classes.header}>
                <div>
                    <Button type="primary" style={{margin: "0 15px 0 0"}} onClick={() => history.push("orders/new")}>{t("New order")}</Button>
                    {(props.role === 'admin' || props.role === 'manager') && <span className={classes.link} onClick={goToReport}>{t("Reports")}</span>}
                </div>
                <div>
                    {(props.role === 'admin' || props.role === 'manager') && <SwitchTabs
                        tabs={[{label: t('List'), value: 'list'}, {label: t('Grid'), value: 'grid'}]}
                        selected={tab}
                        onSwitch={(x) => setTab(x)}
                    />}
                </div>
                <div>
                    {(props.role === 'admin' || props.role === 'manager') && <Select
                        style={{margin: 0}}
                        name="office"
                        options={officeList}
                        value={{office: selectedOffice}}
                        onChange={(e) => setSelectedOffice(e.target.value)}
                    />}
                </div>
            </div>

            {(props.role === 'admin' || props.role === 'manager') && <>
                <br/><br/>
                <Expired data={orderData} />
                <ThisWeak data={orderData} />
                <NextWeeks data={orderData} />
            </>}

            {props.role === 'seller' && <>
                <Seller data={data.orders} />
            </>}
        </div>
    );
};
