import React, {useEffect, useState} from 'react';
import Input from '../../../UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useMaterial, useSaveMaterial} from '../../../../appolo/admin.queries';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import Button from '../../../UI/Button/Button';
import {MaterialFields} from './Settings';
import classes from './Material.module.scss';
import Select from '../../../UI/Select/Select';

const Material = (props) => {

    const {t} = useTranslation();
    let history = useHistory();
    let { id } = useParams();

    if (props.role !== "admin" && props.role !== "manager") {
        history.push("/");
    }

    const [values, setValues] = useState({data: MaterialFields});
    const [errors, setErrors] = useState({});

    const {loading, error, data} = useMaterial({id: id !== "new" ? id : "00000000-0000-0000-0000-000000000000"});
    const [saveMaterial] = useSaveMaterial();

    useEffect(() => {
        if (!data || data.materials?.length === 0)
            return;

        const newData = {
            name: data.materials[0].name,
            data: data.materials[0].data === "" ? MaterialFields: JSON.parse(data.materials[0].data),
        }

        setValues(newData);
    }, [data]);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const onSave = () => {
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                sendToServer();
            else
                setErrors(errors);
        });
    }

    const sendToServer = () => {
        let variables = {
            id: '00000000-0000-0000-0000-000000000000',
            name: values.name,
            data: JSON.stringify(values.data),
        };

        if (id && id !== "new")
            variables.id = id;

        saveMaterial({variables})
            .then(() => {
                history.push("/materials");
            });
    }

    const onMaterialChange = (item, event) => {
        const newValues = {...values};
        const setting = newValues.data.find((x) => x.id === item.id);
        setting.value = event.target.value;
        setValues(newValues);
    }

    if (id !== "new" && loading) return <div>Loading...</div>;
    if (id !== "new" && error) return <div>Error!</div>;

    return (
        <>
            <h1>Material</h1>
            <div>
                <Input
                    name="name"
                    placeholder={t('Name')}
                    {...regField}
                />

                <div className={classes.materials}>
                {
                    values.data.map(x => {
                        const itemName = `setting-${x.id}`;
                        const itemValue = {[itemName]: x.value};
                        return (
                            <div key={x.id} className={classes.editRow}>
                                {t(x.name)}:
                                <Select options={props.materialQuantity} name={itemName} value={itemValue} onChange={(e) => onMaterialChange(x, e)} style={{margin: 0}} />
                            </div>
                        )})
                }
                </div>

                <div>
                    <Button onClick={onSave} type="primary">{t('Save')}</Button>
                    <Button onClick={() => history.push('/materials')} type="secondary">{t('Cancel')}</Button>
                </div>
            </div>
        </>
    );
};

Material.defaultProps = {
    materialQuantity: [
        {label: 'None', value: 'none'},
        {label: 'Low', value: 'low'},
        {label: 'Medium', value: 'medium'},
        {label: 'High', value: 'high'}
    ],
};

export default Material;
